<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`Invoicing`) }}</h1>

                            <b-dropdown
                                v-if="iCan( 'payments', 'write' )"
                                id="add-new-dropdown"
                                variant="primary"
                                class="float-right"
                                right
                            >
                                <template #button-content>
                                    <feather-icon icon="PlusCircleIcon" />
                                    {{ i18nT(`Add new`) }}
                                </template>

                                <b-dropdown-item>
                                    <b-link :to="{ name: 'create-invoice', params: {type: 1}}">
                                        <font-awesome-icon class="mr-25" :icon="['fas', 'file-invoice-dollar']" />
                                        {{ i18nT(`Invoice`) }}
                                    </b-link>
                                </b-dropdown-item>

                                <b-dropdown-item class="text-danger">
                                    <b-link class="text-danger" :to="{ name: 'create-credit-note', params: {type: 3}}">
                                        <font-awesome-icon class="mr-25" :icon="['fas', 'exchange-alt']" />
                                        {{ i18nT(`Credit note`) }}
                                    </b-link>
                                </b-dropdown-item>

                              <b-dropdown-item
                                  v-b-modal.modal-import-csv
                                  v-if="betaFeature"
                              >
                                <div class="text-primary">
                                  <font-awesome-icon class="mr-25" :icon="['fas', 'file-import']" />
                                  {{ i18nT(`Import`) }}
                                </div>
                              </b-dropdown-item>

                                <b-dropdown-item 
                                        v-b-modal.modal-export
                                        :disabled="totalDocuments == 0"
                                    >
                                    <div :class="{'text-primary': totalDocuments !== 0}">
                                        <font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'file-export']" />
                                        {{ i18nT(`Export`) }}
                                    </div>
                                </b-dropdown-item>

                            </b-dropdown>


                        </b-col>
                    </b-row>


                    <!-- CHART AND REPORTING -->
                    <b-row v-if="documents.length > 0" >
                        <b-col cols="12" md="4">
                            <vue-apex-charts
                                v-if="report && report.currency"
                                id="invoice-summary-chart"
                                type="donut"
                                height="300"
                                :series="series"
                                :options="chartOptions"
                            >

                            </vue-apex-charts>
                        </b-col>
                        <b-col
                            cols="12" md="6"
                            class="budget-wrapper"
                        >
                            <b-row>
                                <b-col cols="6">
                                    <h4>{{i18nT(`Invoiced`)}}</h4>
                                    <div class="mt-1 pb-1 mb-2 border-bottom">
                                        <h5>{{i18nT(`Total Invoiced`)}}</h5>
                                        <h3 v-if="report.total_invoiced" class="mb-25 text-success font-weight-bolder">
                                            {{ {amount: parseFloat(report.total_invoiced).toFixed(2), currency: currency} | currency }}
                                        </h3>
                                        <h4 v-else class="text-muted">
                                            {{i18nT(`No data available`)}}
                                        </h4>
                                    </div>

                                    <div v-for="chartTotal in sideSeries.actual" :key="chartTotal.name" class="mt-1">
                                        <h5 v-if="chartItemHasData(chartTotal)">{{chartTotal.name}}</h5>
                                        <h3 v-if="chartItemHasData(chartTotal)" class="mb-25" :class="`text-`+chartTotal.color">
                                            {{ {amount: parseFloat(chartTotal.data).toFixed(2), currency: currency} | currency }}
                                        </h3>
                                    </div>


                                </b-col>
                                <b-col cols="6">
                                    <h4>{{i18nT(`Balance`)}}</h4>
                                    <div class="mt-1 pb-1 mb-2 border-bottom">
                                        <h5>{{i18nT(`Not paid`)}}</h5>
                                        <h3 v-if="report.total_not_paid" class="mb-25 text-danger font-weight-bolder">
                                            {{ {amount: parseFloat(report.total_not_paid).toFixed(2), currency: currency} | currency }}
                                        </h3>
                                        <h4 v-else class="text-muted">
                                            {{i18nT(`No data available`)}}
                                        </h4>
                                    </div>


                                    <div v-for="chartTotal in sideSeries.remaining" :key="chartTotal.name" class="mt-1">
                                        <h5 v-if="chartItemHasData(chartTotal)">{{chartTotal.name}}</h5>
                                        <h3
                                            v-if="chartItemHasData(chartTotal)"  class="mb-25" :class="`text-`+chartTotal.color">
                                            {{ {amount: chartTotal.data, currency: currency} | currency }}
                                        </h3>

                                    </div>
                                </b-col>
                            </b-row>


                        </b-col>
                    </b-row>

                    <div class="mb-1">
                        <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                dataMeta.from
                            }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                dataMeta.of
                            }} {{ i18nT(`entries`) }}</span>
                    </div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="3"
                        >
                            <b-form-group
                                :label="i18nT(`Reporting period`)"
                                label-for="paymentType"
                                v-if="controlVisible"
                            >
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-button
                                            variant="primary"
                                            @click.stop="inputClick"
                                        >
                                            <font-awesome-icon  :icon="['fas', 'calendar-alt']" />
                                        </b-button>
                                    </b-input-group-prepend>
                                    <VueCtkDateTimePicker
                                        :id="'InvoiceDateRange'"
                                        class=""
                                        :range="true"
                                        :min-date="minDate"
                                        :disabled="false"
                                        v-model="reportRange"
                                        :only-date="true"
                                        :only-time="false"
                                        :format="`YYYY-MM-DD`"
                                        :formatted="dateFormat"
                                        :color="`#5a5aff`"
                                        :label="i18nT(`Select date`)"
                                        :button-now-translation="i18nT(`Today`)"
                                        :first-day-of-week="1"
                                        :no-button="true"
                                        :auto-close="true"
                                        :locale="locale"
                                        :ref="`reportRangePicker`"
                                    />
                                </b-input-group>

                            </b-form-group>
                        </b-col>

                        <!-- Vendors -->
                        <b-col
                            cols="12"
                            md="3"
                            v-if="displayExtentControls && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Client`)"
                                label-for="assignee"
                            >
                                <v-select
                                    id="assignee"
                                    v-model="vendor"
                                    :options="vendors"
                                    :clearable="false"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>


                        <b-col
                            cols="12"
                            md="3"
                        >
                            <b-form-group
                                :label="i18nT(`Category`)"
                                label-for="paymentType"
                                v-if="controlVisible"
                            >
                                <v-select
                                    id="paymentType"
                                    v-model="paymentCategory"
                                    :options="paymentCategoryList"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="3"
                        >
                            <b-form-group
                                :label="i18nT(`Payment method`)"
                                label-for="paymentType"
                                v-if="controlVisible"
                            >
                                <v-select
                                    id="software"
                                    v-model="paymentType"
                                    :options="paymentTypes"
                                    :clearable="false"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>

                    </b-row>
                </div>
            </div>

            <div>
                <div>
                    <div
                        class="row mx-2"
                        v-if="displayExtentControls"
                    >
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0"
                            >
                                <!-- <b-dropdown-item
                                    v-if="iCan( 'payments', 'write' )"
                                    @click="onBulkCopy">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item> -->
                                <b-dropdown-item
                                    v-if="iCan( 'payments', 'write' )"
                                    @click="onBulkDelete">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>

                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refDocumentListTable"
                        :items="fetchDocuments"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >

                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.Id"
                            />
                        </template>

                        <template #cell(number)="data">
                            <div class="d-flex justify-content-start align-items-center">
                                <span class="cursor-pointer d-inline-block text-primary mr-1"
                                      @click="previewInModal(data)"
                                      v-b-tooltip="data.item.RecordType == 3 ? i18nT(`Preview credit note`) : i18nT(`Preview invoice`)">

                                    <font-awesome-icon
                                        v-if="data.item.RecordType == 3"
                                        class="mr-25" :icon="['fas', 'exchange-alt']" />

                                    <font-awesome-icon
                                        v-else
                                        class="mr-25" :icon="['fas', 'file-invoice-dollar']" />

                                </span>
                                <b-link
                                    :class="data.item.PaidStatus == 'void' ? 'opacity-08' : ''"
                                    :to="{ name: 'invoice-preview', params: { id: data.item.Id } }"
                                   >
                                    <span v-if="data.item.Prefix">{{ data.item.Prefix}}{{ data.item.InvoiceNumber }}</span>
                                    <span v-else>{{ data.item.InvoiceNumber }}</span>
                                    <p class="font-small-2 mb-0 text-primary">{{ getCategoryName(data.item.Category) }}</p>
                                </b-link>
                            </div>

                        </template>

                        <template #cell(payment_type)="data">
                            <span :class="data.item.PaidStatus == 'void' ? 'opacity-08' : ''">
                                {{ data.item.PaymentTypeObj.label }}
                            </span>
                        </template>

                        <template #cell(vendor)="data">
                            <span :class="data.item.PaidStatus == 'void' ? 'opacity-08' : ''"
                                v-if="data.item.VendorObj">{{ data.item.VendorObj.Label }}</span>
                        </template>

                        <template #cell(total_amount)="data">
                            <span :class="data.item.Type == 1 ? 'text-success' : 'text-danger'">
                                {{data.item.Type == 1 ? '+' : '-'}}{{ {amount: data.item.TotalAmount, currency: data.item.Currency } | currency }}
                            </span>
                        </template>


                        <template #cell(invoice_date)="data">
                            <span :class="data.item.PaidStatus == 'void' ? 'opacity-08' : ''">
                                {{ data.item.InvoiceDate | date }}
                            </span>
                        </template>

                        <template #cell(due_date)="data">
                            <div class="text-center">
                                <span v-if="data.item.DueDate" :class="data.item.PaidStatus == 'void' ? 'opacity-08' : ''">
                                    {{ data.item.DueDate | date }}
                                </span>
                                <span v-else class="align-text-center text-muted" >
                                    N/A
                                </span>
                            </div>
                        </template>

                        <template #cell(PaidStatus)="data">
                            <b-badge
                                :variant="statusLabelClass(data.item.PaidStatus)"
                                class="mr-1 badge-pill">
                                {{statusLabel(data.item.PaidStatus)}}
                            </b-badge>
                        </template>


                        <template #cell(DueAmount)="data">
                            <span :class="`text-`+statusLabelClass(data.item.PaidStatus)">
                                {{ {amount: data.item.DueAmount, currency: data.item.Currency } | currency }}
                            </span>
                        </template>


                        <template #cell(action)="data">
                            <b-dropdown
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                            >

                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>

                                <b-dropdown-item :to="{ name: 'invoice-preview', params: { id: data.item.Id } }">
                                    <feather-icon icon="EyeIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Preview`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item :to="{ name: data.item.RecordType == 1 ? 'edit-invoice' : 'edit-credit-note', params: { id: data.item.Id } }">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item :href="'/invoice/' + data.item.Id + '/h/' + data.item.Hash + '?lang=' + locale" target="_blank">
                                    <feather-icon icon="LinkIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Open public link`) }}</span>
                                </b-dropdown-item>

<!--                              SEND-->
                              <b-dropdown-item v-b-toggle.sidebar-send-invoice @click="setSendInvoiceObj(data.item)">
                                <feather-icon icon="SendIcon"/>
                                <span class="align-middle ml-50">{{ i18nT(`Send`) }}</span>

                              </b-dropdown-item>

                                <b-dropdown-item :href="data.item.InvoiceDownloadUrl + '&lang=' + locale" target="_blank">
                                    <feather-icon icon="DownloadIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Download`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="data.item.PaidStatus !== 'paid' && data.item.RecordType !== 3"
                                    @click="createCreditNote(data.item)">
                                    <font-awesome-icon
                                        :icon="['fas', 'exchange-alt']" />
                                    <span class="align-middle ml-50">{{ i18nT(`Create credit note`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="data.item.PaidStatus !== 'paid' && data.item.PaidStatus !== 'overpaid' && data.item.RecordType !== 3"
                                    @click="setPaymentInvoiceObj(data.item)">
                                    <feather-icon icon="PlusIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Record payment`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="iCan( 'payments', 'write' )"
                                    @click="onSingleCopy(data.item)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <!-- <b-dropdown-item @click="onCopy(data)">
                                  <feather-icon icon="EditIcon" />
                                  <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                                </b-dropdown-item> -->
                                <b-dropdown-item
                                    v-if="data.item.PaidStatus === 'not_paid'"
                                    @click="toggleVoid(data.item.Id)">
                                    <feather-icon
                                        icon="ArchiveIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Mark as Void`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="data.item.PaidStatus === 'void'"
                                    @click="toggleVoid(data.item.Id)">
                                    <feather-icon
                                        icon="CornerUpLeftIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Mark as Not Paid`) }}</span>
                                </b-dropdown-item>


                                <b-dropdown-item
                                    @click="onDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>

                </div>

                <div class="mx-1 mb-2">
                    <b-row>
                        <!-- <b-col> -->
                        <!-- </b-col> -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                    dataMeta.from
                                }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                    dataMeta.of
                                }} {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>
        </b-card>
      <invoice-sidebar-send-invoice @sent="refetchInvoiceData" />
        <invoice-sidebar-add-payment
            :visible="isAddPaymentVisible"
            :invoice-id="invoiceData.Id"
            :due-amount="invoiceData.DueAmount ? parseFloat(invoiceData.DueAmount) : 0"
            :currency="invoiceData.Currency ? invoiceData.Currency : 'EUR'"
            :invoice-status="invoiceData.PaidStatus"
            :cur-locale="locale"
            @updated="refetchInvoiceData"
            @close="closeAddPayment"
        />
        <b-modal
            id="preview-invoice-modal"
            title="Invoice Preview"
            class="overflow-y-scroll"
            hide-footer
            size="lg"
            centered
            :hide-header="true"
        >
            <div class="frame-container">
                <h4 class="text-center py-2">{{i18nT(`Invoice preview`)}}</h4>
                <iframe
                    :src="previewIframeUrl+'&lang='+locale"
                    width="100%"
                    height="100%"
                    frameborder="0"
                ></iframe>

                <div id="frame-spinner"
                     class="position-absolute
                                    w-100
                                    d-flex justify-content-center
                                    align-items-center align-content-center">
                    <b-spinner label="Loading..."
                               variant="primary"
                               class="mr-2"
                               color="primary"></b-spinner>
                </div>
            </div>
        </b-modal>
      <!-- MODAL IMPORT CSV -->
      <modal-import-csv class-name="invoices" @imported="refetchData" :import-type="importType">
            <b-row class="pt-2">
                <b-col cols="12" md="3">
                    <h5>
                        {{ i18nT(`Select document type`) }}
                    </h5>
                </b-col>
                <b-col cols="12" md="9">
                    <v-select 
                        v-model="importType"
                        :options="importOptions"
                        label="text"
                        class="pb-1"
                        :clearable="false"
                    />
                </b-col>
            </b-row>
        </modal-import-csv>

        <modal-export module="invoices" :allFilters="allFilters"/>
    </div>
</template>

<script>
import {
  BLink,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
  BFormInput,
  VBTooltip,
  VBToggle,
  BBadge,
  BInputGroupPrepend,
  BInputGroup, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useInvoiceList from './useInvoiceList'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import router from '@/router'
import useAuth from '@/auth/useAuth'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from 'moment';
import InvoiceSidebarAddPayment from "@/views/apps/invoice/InvoiceSidebarAddPayment.vue";
import InvoiceSidebarSendInvoice from "@/views/apps/invoice/InvoiceSidebarSendInvoice.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ModalImportCsv from "@/views/common/ModalImportCsv/ModalImportCsv.vue";
import ModalExport from "@/views/common/ModalExport/ModalExport.vue"


export default {
    components: {
        ModalImportCsv,
        ModalExport,
        BSpinner,
        BInputGroupPrepend,
        BBadge,
        BLink,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        BFormCheckbox,
        BFormInput,
        VueApexCharts,
        VueCtkDateTimePicker,
        BInputGroup,
        InvoiceSidebarAddPayment,
        InvoiceSidebarSendInvoice
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
    },
    // props: {
    //     selectedFinanceType: {
    //         type: String
    //     }
    // },
    data() {
        const displayExtentControls =
            useAuth.getCurrentSoftware() != 'ca' &&
            useAuth.getCurrentSoftware() != 'em'
        return {
            invoiceData: {},
            previewIframeUrl: null,
            isAddPaymentVisible: false,
            isSendSideBarVisible: false,
            currency: 'EUR',
            report: {},
            reportRange: {
                start: this.startDate,
            },
            invoiceTypes: [],
            paymentCategoryList: [],
            vendors: [],
            paymentTypes: [],
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            displayExtentControls,
            avalableFields: [
                {label: this.i18nT(`Invoice date`), key: 'invoice_date', visible: true},
                {label: this.i18nT(`Due date`), key: 'due_date', visible: false},
                {label: this.i18nT(`Total Amount`), key: 'total_amount', visible: true},
                {label: this.i18nT(`Vendor`), key: 'vendor', visible: true},
                {label: this.i18nT(`Status`), key: 'PaidStatus', visible: true},
                {label: this.i18nT(`Open balance`), key: 'DueAmount', visible: true},
                {label: this.i18nT(`Payment type`), key: 'payment_type', visible: false},
            ],
            documentFilters: [
                {value: '', text: this.i18nT(`All`)},
                {value: 'created', text: this.i18nT(`Created by me`)},
                {value: 'shared', text: this.i18nT(`Shared by me`)}
            ],
            controlVisible: true,
            importOptions: [
                {
                    text: this.i18nT(`Invoice`), module: 'invoices', import_type: 1
                },
                {
                    text: this.i18nT(`Credit note`), module: 'invoices', import_type: 2
                }
                 
            ],
            importType: {} ,
            emptyHtml: "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
            + this.i18nT(`Here, you can create and manage your invoices. No invoices have been created yet.`) + "</h4>"
            +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/ac/invoices/create/1\"'>" + this.i18nT(`Create invoice`) + "</button>",

        }
    },
    created() {

        let storePreferences = useAuth.getColumnPreferences('InvoiceTemplates')
        if(storePreferences) {
            this.avalableFields = storePreferences
        }
        
        //GET CATEGORIES
        this.$http.get(
            `invoices/categories?type=1`
        ).then(({data}) => {

            let validCategories = data.data.filter(category => {
                return category.RecordType == 1 || category.RecordType == 2 || category.RecordType == 3;
            });

            this.paymentCategoryList = validCategories.map(category => {
                return {
                    value: category.Id,
                    text: category.Label,
                    fullData: category
                }
            })
            this.paymentCategoryList.unshift({
                value: -1,
                text: this.i18nT(`Any`)
            })
        })

        //GET VENDORS
        this.$http.get(`vendors/vendorsList?invoicing=1`).then(({data}) => {
            this.vendors = dictToSelectArray(data.data)
            this.vendors.unshift({
                value: -1,
                text: this.i18nT(`All`)
            })
        })

        //GET PAYMENT TYPES
        this.$http.get(`system/paymentTypes`).then(({data}) => {
            this.paymentTypes = dictToSelectArray(data.data)
            this.paymentTypes.unshift({
                value: -1,
                text: this.i18nT(`All`)
            })
        })

        //GET STATS SUMMARIES
        this.$http.get(`invoices/paymentsSummary?start_date=`+this.reportRange.start).then(({ data }) => {
            this.report = data.data
            this.currency = data.data.currency
        })

    //   this.fetchDocuments(null, this.determineVisibility);

    },
    methods: {
        loadOldInvoices() {
            this.reportRange.start = moment(this.lastInvoiceDate).startOf('year').format('YYYY-MM-DD')
        },
        previewInModal(data) {
            this.previewIframeUrl = data.item.IframePublicUrl
            this.$bvModal.show('preview-invoice-modal')
        },
        chartItemHasData(chartTotal) {
            return typeof chartTotal.data != 'undefined' && chartTotal.data != 'NaN' && chartTotal.data !== '0.00'
        },
        refetchInvoiceData(){
            this.refetchData()
            this.refetchSummaries()
        },
        refetchSummaries(){
            let filtersString = '';
            if(this.paymentType && this.paymentType.value != -1){
                filtersString += `&payment_type=${this.paymentType.value}`
            }
            if(this.vendor && this.vendor.value != -1){
                filtersString += `&vendor_id=${this.vendor.value}`
            }
            if(this.paymentCategory && this.paymentCategory.value != -1){
                filtersString += `&category_id=${this.paymentCategory.value}`
            }

            this.$http.get(`invoices/paymentsSummary?start_date=${this.startDate}&end_date=${this.endDate}`+filtersString).then(({ data }) => {
                this.report = data.data
                this.currency = data.data.currency
            })
        },
        toggleVoid(itemId) {
            this.$http
                .post(`invoices/toggleVoid?invoice_id=${itemId}`)
                .then(() => {
                    this.refetchData()
                    this.refetchSummaries()
                })
        },
        createCreditNote(item) {
            this.$router.push({name: 'create-credit-note', params: {invoiceId: item.Id}})
        },
        setSendInvoiceObj(invoiceObj) {
            this.$store.commit('invoices/SET_INVOICE_DETAILS', invoiceObj);
            this.openSendInvoiceModal();
        },
        openSendInvoiceModal() {
            this.isSendSideBarVisible = true;
            console.log('VISIBLE', this.isSendSideBarVisible);
        },

        setPaymentInvoiceObj(invoiceObj) {
            this.invoiceData = invoiceObj;
            this.openAddPaynmentModal();
        },
        openAddPaynmentModal() {
            if(this.isAddPaymentVisible) {
                this.isAddPaymentVisible = false;
            }
            setTimeout(() => {
                this.isAddPaymentVisible = true;
            }, 0);
        },
        closeAddPayment() {
            this.isAddPaymentVisible = false;
        },
        inputClick() {
            const inputElement = this.$refs[`reportRangePicker`].$el.querySelector('input');
            if (inputElement) {
                inputElement.click();
            }
        },
        getCategoryName(typeId) {
            const invoiceType = this.paymentCategoryList.find(type => type.value == typeId)
            return invoiceType ? invoiceType.text : ''
        },
        statusLabel(status){
            return {
                'paid': this.i18nT(`Paid`),
                'partial': this.i18nT(`Partial`),
                'not_paid': this.i18nT(`Not paid`),
                'void': this.i18nT(`Void`),
                'overpaid': this.i18nT(`Overpaid`),
                'overdue': this.i18nT(`Overdue`)
            }[status]
        },
        statusLabelClass(status) {
            return {
                'paid': 'success',
                'partial': 'warning',
                'not_paid': 'danger',
                'overdue': 'dark-danger',
                'overpaid': 'info',
                'void': 'light-dark',
            }[status]
        },
        getAssigneeName(assignee) {
            const assigneeList = Object.values(assignee)
            return assigneeList.map(ass => ass.Label).join(', ')
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(element) {
            this.$http
                .post(`invoices/copy?invoice_id=${element.item.Id}`)
                .then(() => {
                    this.refetchData()
                })
        },
        onDelete(task) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`invoices?id=${task.item.Id}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The document has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                        this.refetchSummaries()
                    }).catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
                }
            })
        },
        onSingleCopy(element) {
            this.$http
                .post(`invoices/copy?invoice_id=${element.Id}`)
                .then((resp) => {

                    this.$router.push({name: 'edit-invoice', params: {id: resp.data.data.Id}});
                })
        },
        onBulkCopy() {
            this.$http
                .post(
                    `invoices/copyInvoice?invoice_id=${this.selectedItems.join(',')}`
                )
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`invoices?id=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text:  this.i18nT(`Selected invoice(s) were deleted!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    updated(){
        const leaveBtn = document.querySelector('#loadOldInvoices')
        if(leaveBtn) {
            leaveBtn.addEventListener('click', this.loadOldInvoices)
        }
    },
    watch: {
        documents: {
            handler: function(val) {

                if(val.length === 0 && this.lastInvoiceDate !== null) {
                    this.emptyHtml = "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
                    + this.i18nT(`It seems you don't have any invoices for the selected period.<br/><br/>Click the button below to view the latest invoices.`) + "</h4>"
                    +"<button class='btn btn-warning mt-2' id='loadOldInvoices'>" + this.i18nT(`Load past invoices`) + "</button>"
                } else if (val.length === 0 && this.lastInvoiceDate === null) {
                    this.controlVisible = false
                }
            },
            deep: true
        },
        paymentCategory(newVal) {
            if (newVal) {
                this.refetchSummaries()
            }
        },
        paymentType(newVal) {
            if (newVal) {
                this.refetchSummaries()
            }
        },
        vendor(newVal) {
            if (newVal) {
                this.refetchSummaries()
            }
        },
        reportRange: {
            deep: true,
            handler(newRange) {
                if(newRange) {
                    if(newRange.start) {
                        this.startDate = moment(newRange.start).format('YYYY-MM-DD');
                        this.endDate = newRange.end ? moment(newRange.end).format('YYYY-MM-DD') : '';
                    }
                    this.refetchSummaries();
                } else {
                    this.startDate = '';
                    this.endDate = '';
                    this.refetchSummaries();
                }

            }
        },
    },
    setup() {
        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            recordType,
            assignedTo,
            vendor,
            paymentType,
            paymentCategory,
            startDate,
            endDate,
            documents,
            documentFilter,
            lastInvoiceDate,
            refetchData
        } = useInvoiceList(router.currentRoute.meta.template === 1)

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            recordType,
            assignedTo,
            vendor,
            paymentType,
            paymentCategory,
            startDate,
            endDate,
            avatarText,
            documents,
            documentFilter,
            lastInvoiceDate,
            refetchData
        }
    },
    computed: {
        computedColumns() {
            let col = [...this.tableColumns]
            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('InvoiceTemplates', this.avalableFields);
            return col
        },
        dateFormat() {
            return useAuth.getDateFormat() !== 'undefined'
                ? useAuth.getDateFormat()
                : 'DD/MM/YYYY'
        },
        locale() {
            return useAuth.getLocale() !== 'undefined'
                ? useAuth.getLocale()
                : 'en'
        },
        minDate() {
            return moment('2022-01-01').format();
        },
        allFilters() {
            const params = {
                page: this.currentPage,
                show_per_page: this.perPage,
                sort_by: this.sortBy,
                sort_order: this.isSortDirDesc ? 'DESC' : 'ASC',
                search: this.searchQuery,
                start_date: this.startDate,
                end_date: this.endDate,
                invoicing: 1,
                export: 1,
            };

            if (this.isTemplate) {
                params.templates = 1;
            }
            if (this.recordType.value != -1) {
                params['filters[record_type]'] = this.recordType.value;
            }
            if (this.vendor.value != -1) {
                params['filters[vendor]'] = this.vendor.value;
            }
            if (this.paymentType.value != -1) {
                params['filters[payment_type]'] = this.paymentType.value;
            }
            if (this.paymentCategory.value != -1) {
                params['filters[category]'] = this.paymentCategory.value;
            }
            if(this.startDate != ''){
                params['filters[start_date]'] = this.startDate;
            }
            if(this.endDate != ''){
                params['filters[end_date]'] = this.endDate;
            }

            return params
        },
        chartOptions() {
            // const categories = []
            let chartOptions = []
            //let colors = this.colors;

            chartOptions = {
                chart: {
                    type: 'donut',
                    height: '100%',
                    width: '100%'  // or any specific pixel value
                },
                labels: this.labels,
                dataLabels: {
                    enabled: true,
                    formatter: function(val) {
                        return val.toFixed(2)+"%";
                    },
                    style: {
                        colors: ['#fff'] // This will set the color of the percentage text. Change as needed.
                    }
                },
                colors: [
                    '#28c77a', //Paid
                    '#ff9800', //Partial
                    '#00cfe8', //Overpaid

                    '#ea5455', //Not paid
                    '#79082A', //Overdue
                    'rgba(185,185,195,0.6)', //Void

                ],

                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
            return chartOptions
        },
        sideSeries() {
            let sideSeries = {
                actual: [
                    {name: this.i18nT(`Paid in full`), data: parseFloat(this.report.total_paid).toFixed(2), color: 'success'},
                    {name: this.i18nT(`Overpaid`), data: parseFloat(this.report.total_overpaid).toFixed(2), color: 'info'},
                    {name: this.i18nT(`Partial payments`), data: parseFloat(this.report.total_partial).toFixed(2), color: 'warning'},
                ],
                remaining: [
                    {name: this.i18nT(`Overdue`), data: parseFloat(this.report.total_overdue).toFixed(2), color: 'dark-danger'},
                    {name: this.i18nT(`Void`), data: parseFloat(this.report.total_void).toFixed(2), color: 'muted'},
                ]
            }
            return sideSeries
        },

        series() {
            let series = []

            if (this.report) {

                    series = [
                        this.report.total_paid,
                        this.report.total_partial,
                        this.report.total_overpaid,
                        this.report.total_not_paid,
                        this.report.total_overdue,
                        this.report.total_void,
                    ]
            }
            return series
        },
        labels() {
            let labels = []

            if (this.report) {
                labels = [
                    this.i18nT(`Paid in full`),
                    this.i18nT(`Partial payment`),
                    this.i18nT(`Overpaid`),

                    this.i18nT(`Not paid`),
                    this.i18nT(`Overdue`),
                    this.i18nT(`Void`),
                ]
            }
            return labels
        }
    }

}
</script>

<style lang="scss">

#preview-invoice-modal.modal .modal-content {
    overflow: hidden;
    overflow-y: scroll;
}


.frame-container {
    position: relative;
    width: 100%;
    height: 820px;
    min-height: 720px;
}

.frame-container iframe {
    width: 100%;
    height: 100%;
    border: none;
    z-index: 20;
    position: relative;
}

#frame-spinner {
    display: block;
    z-index: 2;
    top: 50%;
}


.chart-color-1 {
    background-color: #28c77a;
}
.chart-color-2 {
    background-color: #1caf62;
}
.chart-color-3 {
    background-color: #ff2667;
}
.chart-color-4 {
    background-color: #C21146;
}

.chart-color-5 {
    background-color: #79082A;
}

div.date-time-picker {
    width: calc(100% - 56px);
}

.date-time-picker input.field-input {
    padding-right: 5px;
}

.ctk-date-time-picker__header,
.datetimepicker .custom-button,
.datetimepicker span.custom-button-content,
.datepicker-buttons-container .datepicker-button.now .datepicker-button-content
{
    /* Change the header text color */
    color: #5a5aff !important;
    fill: #5a5aff !important;
}

.sk-column-select {
    width: 180px;
}


.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
